var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl-inline-flex gl-h-full gl-align-top",class:{
    'is-draggable': _vm.isListDraggable,
    '-gl-mr-3': !_vm.canAdminList && _vm.last,
  },attrs:{"data-list-id":_vm.list.id,"data-testid":"board-list"}},[_c('div',{staticClass:"board is-expandable gl-relative gl-inline-block gl-h-full gl-whitespace-normal gl-px-3 gl-align-top",class:{
      'is-collapsed gl-w-10': _vm.list.collapsed,
      'board-type-assignee': _vm.list.listType === 'assignee',
    }},[_c('div',{staticClass:"gl-relative gl-flex gl-h-full gl-flex-col gl-rounded-base gl-bg-gray-50",class:{ 'board-column-highlighted': _vm.highlighted }},[_c('board-list-header',{attrs:{"list":_vm.list,"filter-params":_vm.filters,"board-id":_vm.boardId},on:{"toggleNewForm":_vm.toggleNewForm,"setActiveList":function($event){return _vm.$emit('setActiveList', $event)}}}),_vm._v(" "),_c('board-list',{ref:"board-list",attrs:{"board-id":_vm.boardId,"list":_vm.list,"filter-params":_vm.filters,"show-new-form":_vm.showNewForm,"column-index":_vm.columnIndex},on:{"toggleNewForm":_vm.toggleNewForm,"setFilters":function($event){return _vm.$emit('setFilters', $event)},"cannot-find-active-item":function($event){return _vm.$emit('cannot-find-active-item')}}})],1),_vm._v(" "),(_vm.showAddNewListBetween)?_c('div',{staticClass:"gl-absolute gl-bottom-0 gl-right-0 gl-top-0 gl-z-1 gl-translate-x-1/2"},[_c('board-add-new-column-between',{on:{"setAddColumnFormVisibility":_vm.setShowNewListAfter}})],1):_vm._e()]),_vm._v(" "),(_vm.showNewListForm)?_c('div',{staticClass:"gl-pl-2 gl-pr-3"},[_c('board-add-new-column',{attrs:{"board-id":_vm.boardId,"list-query-variables":_vm.listQueryVariablesWithCreateNewPosition,"lists":_vm.lists,"position":_vm.createNewPosition},on:{"setAddColumnFormVisibility":_vm.setShowNewListAfter,"highlight-list":function($event){return _vm.$emit('highlight-list', $event)}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }