var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mounting-portal',{attrs:{"mount-to":"#js-right-sidebar-portal","name":"board-settings-sidebar","append":""}},[_c('gl-drawer',_vm._b({staticClass:"js-board-settings-sidebar boards-sidebar",attrs:{"open":_vm.showSidebar,"variant":"sidebar"},on:{"close":_vm.unsetActiveListId},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"gl-my-0 gl-text-size-h2 gl-leading-24"},[_vm._v("\n        "+_vm._s(_vm.$options.listSettingsText)+"\n      ")])]},proxy:true},{key:"header",fn:function(){return [(_vm.canAdminList && _vm.activeList.id)?_c('div',{staticClass:"gl-mt-3"},[_c('gl-button',{directives:[{name:"gl-modal",rawName:"v-gl-modal",value:(_vm.$options.modalId),expression:"$options.modalId"}],attrs:{"variant":"danger","category":"secondary","size":"small"}},[_vm._v(_vm._s(_vm.__('Remove list'))+"\n        ")])],1):_vm._e()]},proxy:true}])},'gl-drawer',_vm.$attrs,false),[_vm._v(" "),_vm._v(" "),(_vm.showSidebar)?[(_vm.boardListType === _vm.ListType.label)?_c('div',[_c('label',{staticClass:"js-list-label gl-block"},[_vm._v(_vm._s(_vm.listTypeTitle))]),_vm._v(" "),_c('gl-label',{attrs:{"title":_vm.activeListLabel.title,"background-color":_vm.activeListLabel.color,"scoped":_vm.showScopedLabels(_vm.activeListLabel)}})],1):_c('board-settings-list-types',{attrs:{"active-list":_vm.activeList,"board-list-type":_vm.boardListType}}),_vm._v(" "),(_vm.isWipLimitsOn)?_c('board-settings-sidebar-wip-limit',{attrs:{"max-issue-count":_vm.activeList.maxIssueCount,"active-list-id":_vm.activeListId}}):_vm._e()]:_vm._e()],2),_vm._v(" "),_c('gl-modal',{attrs:{"modal-id":_vm.$options.modalId,"title":_vm.$options.i18n.modalAction,"size":"sm","action-primary":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */ {
      text: _vm.$options.i18n.modalAction,
      attributes: { variant: 'danger' },
    } /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */,"action-secondary":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */ {
      text: _vm.$options.i18n.modalCancel,
      attributes: { variant: 'default' },
    } /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */},on:{"primary":_vm.handleModalPrimary}},[_c('p',[_vm._v(_vm._s(_vm.$options.i18n.modalCopy))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }